/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ToastContainer } from 'react-toastify'
import SqaureLoader from '../../components/Loader/SqaureLoader';
import PaginationTable from '../../components/Pagination/PaginationTable';
import { listeEnseignes } from '../../services/Magasins/PdvApi';
 import { getMerch } from '../../services/Users/UsersApi';
 import { getlistePartLineaire, searchPartLineaireTotal } from '../../services/PartLineaire/partLineaireAPI';
import { getListeFamilles } from '../../services/Produits/configurationProduits/ConfigurationAPI';
import ModalPartLineaire from './ModalPartLineaire';

export default function PartLineaireTotal() {
    const { debut, fin } = useParams()
    const history = useHistory();
    //------ DATE SEARCH (debut / fin) -----------
    const [dateDebut, setDateDebut] = useState(debut);
    const [dateFin, setDateFin] = useState(fin);
    //-- Liste notes ------------
    const [liste, setliste] = useState([])
    const [listepartlineaire, setlistepartlineaire] = useState([])
    const [pdv, setpdv] = useState([])
    const [total, settotal] = useState("")
    const [listeMerch, setlisteMerch] = useState([])
    const [listeFamilles, setlisteFamilles] = useState([])
    const [codeMerch, setcodeMerch] = useState("")
    const [codeEnseigne, setcodeEnseigne] = useState("")

    const [listeHeaders, setlisteHeaders] = useState({})

    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(36);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = liste?.slice(indexOfFirstPost, indexOfLastPost)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //Loader 
    const [Loading, setLoading] = useState(true)

    useEffect(() => {
        listeEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setpdv(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getMerch()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteMerch(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
            
            const data = {}
            searchPartLineaireTotal( dateDebut, dateFin, data)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setliste(result?.data?.liste)
                    settotal(result?.data?.total?.total)
                    setlisteHeaders(result?.data?.headerTable)
                }
                setLoading(false)
            }).catch((err) => {
                console.log(err)
            });
            getListeFamilles()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteFamilles(result?.data?.data)
                }
                
            }).catch((err) => {
                console.log(err)
            });
    }, [])

    const onSearchData = () => {
        setLoading(true)
        history.push({
            pathname: "/partlineairetotal/datedebut=" + dateDebut + "&datefin=" + dateFin
        })
        const data = {}
        if(codeMerch !== ""){
            Object.assign(data, {
                user_code: codeMerch
            })
        }
       
    if (codeEnseigne !== "") {
        Object.assign(data, {
            enseigne_code: codeEnseigne
        })
    }
    searchPartLineaireTotal( dateDebut, dateFin, data)
    .then((result) => {
        if (result?.data?.success === 1) {
            setliste(result?.data?.liste)
            settotal(result?.data?.total?.total)
        }
        setLoading(false)
    }).catch((err) => {
        console.log(err)
    });
       
    }
    const onClickBack = () => {
        const data = {}
        setLoading(true)
        searchPartLineaireTotal( dateDebut, dateFin, data)
        .then((result) => {
            if (result?.data?.success === 1) {
                setliste(result?.data?.liste)
                settotal(result?.data?.total?.total)
            }
            setLoading(false)
        }).catch((err) => {
            console.log(err)
        });
    }
    return (
        <div>
            {/** ----------------  PART LINEAIRE ------------------------- */}
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('dashboard.pl')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('listepartlineaire')}</li>
                </ol>
            </div>
            <ToastContainer />
            {/** ----------------- SECTION  : RECHERCHE  --------------------- */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" />{t('dashboard.pl')} 
                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className="form row no-gutters">
                                <div className="form-group  col-xl-3 col-lg-3 col-md-3 col-6 mb-0">
                                    <input
                                        className="form-control fc-datepicker fs-12"
                                        type="date"
                                        value={dateDebut}
                                        onChange={(e) => setDateDebut(e.target.value)}
                                    />
                                </div>
                                <div className="form-group  col-xl-3 col-lg-3 col-md-3 col-6 mb-0">
                                    <input
                                        className="form-control fc-datepicker fs-12"
                                        type="date"
                                        value={dateFin}
                                        onChange={(e) => setDateFin(e.target.value)}
                                    />
                                </div>
                                <div className="form-group  col-xl-2 col-lg-2 col-md-2 mb-0">
                                    <select className="form-control br-md-0 fs-12" value={codeEnseigne} onChange={(e) => setcodeEnseigne(e.target.value)}>
                                        <option value="">  {t('Magasins.Choisirenseigne')}  </option>
                                        {pdv?.map((items, keys) => (
                                            <option key={keys} value={items.code}> {items.nomenseigne} </option>
                                        ))}

                                    </select>
                                </div>
                                <div className="form-group  col-xl-2 col-lg-2 col-md-3 mb-0">
                                    <div className="d-flex" >
                                        <select className="form-control  border-bottom-0 br-md-0" value={codeMerch} onChange={(e) => setcodeMerch(e.target.value)} data-placeholder="Choisir merchandiser">
                                            <option value="">---- {t('dashboard.Merchandiseurs')} ----</option>
                                            <optgroup label="Merchandiser">
                                                {listeMerch?.map((items, keys) => (
                                                    <option key={keys} value={items.value}>{items.name}</option>
                                                ))}

                                            </optgroup>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                    <button
                                        className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16" onClick={() => onSearchData()}>
                                        <i className="fe fe-search" />
                                    </button>
                                </div>
                                <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                    <button
                                        className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16" onClick={()=>onClickBack()}>
                                        <i className="ti-close" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/** --------------------- CONTENUE DE LA PAGE -------------------- */}
            <div className="card">
                <div className="card-body">
                    <div className="row">
                    <div className='col-12 mb-2'>
                                <button className='btn btn-secondary float-right mr-1 fs-12' data-toggle="modal" data-target="#exportMagasins" ><i className="icon icon-share-alt mr-1" /> {t('rapportrupture.exporter')} </button>
                                </div>
                        <div className="table-responsive  product-datatable" style={{ overflow: 'auto', height: '600px' }}>
                            <table id="example" className="table  table-bordered ">
                                <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                                    <tr>
                                        <td colSpan="15" className="text-center bg-purple text-white fs-10" > {t('totalPL')}  : {total}  </td>
                                    </tr>
                                    <tr>
                                    <th className="w-15p fs-10"> {t('rapportrupture.date')} </th>
                                    <th className="wd-15p fs-10">{t('dashboard.Merchandiseurs')}</th>
                                    <th className="wd-15p fs-10">{t('dashboard.region')}</th>
                                    <th className="wd-15p fs-10">{t('rapports.Enseignes')}</th>
                                    <th className="wd-15p fs-10">{t('dashboard.Magasin')}</th>
                                    <th className="wd-15p fs-10">{t('dashboard.Gamme')}</th>
                                    <th className="wd-15p fs-10">{t('dashboard.famille')}</th>
                                    <th className="wd-15p fs-10">{t('rapports.facing')}</th>
                                    <th className="wd-15p fs-10">{t('rapports.facingtotal')}</th>
                                    <th className="wd-15p fs-10">(PL %)</th>
                                    
                                    </tr>
                                </thead>
                                {Loading ? <SqaureLoader /> : (
                                    <tbody>
                                        {currentListe?.map((items, itemsKey) => (
                                        <tr key={itemsKey} className={itemsKey% 2 === 1 ? "fs-10 bg-azure-lightest text-center" : "fs-10 text-center"}>
                                        <td>{moment(items.date).format("DD/MM/YYYY")}</td>
                                        <td>{items.merch}</td>
                                        <td>{items.region}</td>
                                        <td>{items.enseignes}</td>
                                        <td>{items.magasin}</td>
                                        <td>{items.gamme}</td>
                                        <td>{items.famille}</td>
                                        <td>{items.facing}</td>
                                        <td>{items.facing_total}</td>
                                        <td > {parseFloat((items.facing / items.facing_total) * 100).toFixed(2)} </td>
                                        
                                        </tr>
                                    ))}
                                    </tbody>
                                )}

                            </table>
                            <div className="float-right">
                                <PaginationTable
                                    postsPerPage={stocksPerPage}
                                    totalPosts={total}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalPartLineaire liste={liste} t={t}  loading={Loading}  listeHeaders={listeHeaders} />

        </div>
    )
}
